import { type FC, type PropsWithChildren, useEffect } from "react"
import { Toaster } from "react-hot-toast"
// import Tracker from "@openreplay/tracker"
import { useAdminGetSession } from "medusa-react"
import Intercom from "@intercom/messenger-js-sdk"
import type { User } from "@medusajs/medusa"

// @see https://vitejs.dev/guide/env-and-mode.html#env-variables
// const openReplayToken = import.meta.env.VITE_OPEN_REPLAY_TOKEN
// const openReplayToken = undefined

// const tracker = openReplayToken
//   ? new Tracker({ projectKey: openReplayToken, __DISABLE_SECURE_MODE: true })
//   : undefined

const useIntercom = (user: Omit<User, "password_hash"> | undefined) => {
  useEffect(() => {
    if (!user) return

    Intercom({
      app_id: "ecyfhyv1",
      user_id: user.id,
      name: user.first_name,
      email: user.email,
      created_at: Math.floor(new Date(user.created_at).getTime() / 1000),
    })
  }, [user])
}

const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAdminGetSession()

  useIntercom(user)

  // useEffect(() => {
  //   tracker?.start()
  // }, [])

  // useEffect(() => {
  //   if (user?.email) {
  //     tracker?.setUserID(user.email)
  //   }
  // }, [user])

  return (
    <>
      <Toaster
        containerStyle={{
          top: 74,
          left: 24,
          bottom: 24,
          right: 24,
        }}
      />
      {children}
    </>
  )
}

export default BaseLayout
