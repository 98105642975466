/**
 * returns the full admin path using the window object
 * @returns {string} - admin path
 */
export function getFullAdminPath() {
  if (window) {
    const origin = window.location.origin // returns base url with trailing slash

    const adminPath = process.env.ADMIN_PATH ? process.env.ADMIN_PATH : ""

    // Combine origin and adminPath, ensuring no double slashes
    const fullPath = `${origin}/${adminPath}`.replace(/([^:]\/)\/+/g, "$1")

    return fullPath
  }

  return ""
}
